import React from 'react';
import { Button, Select } from '@avila-tek/ui';
import { User } from '../../models';
import ProgressIcon3 from '../icons/ProgressIcon3';

interface SelectLawyerProps {
  setAppointmentStep: React.Dispatch<React.SetStateAction<string>>; // function to set the current appointment step
  setCalendlyUrl: React.Dispatch<React.SetStateAction<string>>; // function to set the calendly URL of the selected lawyer
  setSelectedLawyerId: React.Dispatch<React.SetStateAction<string>>; // function to set the ID of the selected lawyer
  lawyers: User[]; // array of lawyers
  hasDocuments?: boolean; // whether or not the user has documents
}

function SelectLawyer({
  setAppointmentStep,
  setCalendlyUrl,
  lawyers,
  setSelectedLawyerId,
  hasDocuments = false,
}: SelectLawyerProps) {
  // function to get the ID of the lawyer from the calendly URL
  const getLawyerIdFromCalendlyUrl = (calendlyUrl: string) => {
    const matchingLawyer = lawyers.find(
      (lawyer) => lawyer?.calendlyId === calendlyUrl
    );
    return matchingLawyer ? matchingLawyer._id : null;
  };

  // set the calendly URL and the ID of the first lawyer in the list when the component is mounted
  React.useEffect(() => {
    if (lawyers?.length > 0) {
      setCalendlyUrl(lawyers[0].calendlyId);
      setSelectedLawyerId(lawyers[0]._id);
    }
  }, []);

  return (
    <div className="flex ">
      <div className="flex flex-col p-8  w-fit gap-4">
        {/* title */}
        <h1 className="text-black text-xl font-bold">Agenda una cita</h1>
        {/* description */}
        <div className="flex justify-center items-center flex-col p-4 w-full rounded-md bg-secondary-200">
          <h1 className="text-secondary-500 w-2/3 text-md font-semibold text-center">
            Recibe la asesoría personalizada que necesitas
          </h1>
        </div>
        {/* select box for lawyers */}
        <Select
          className="w-full border-gray-200  border"
          label="Selecciona un abogado"
          onChange={(e) => {
            e.preventDefault();
            setCalendlyUrl(e.target.value);
            setSelectedLawyerId(getLawyerIdFromCalendlyUrl(e.target.value));
          }}
        >
          {/* list of options for lawyers */}
          {lawyers?.map((lawyer) => (
            <option key={lawyer._id} value={lawyer?.calendlyId}>
              {`${lawyer?.firstName} ${lawyer?.lastName} (${lawyer?.lawyer?.firm?.name})`}
            </option>
          ))}
        </Select>
        {/* button to proceed to the next step */}
        <Button
          className=" ml-auto bg-secondary-500 w-full font-medium px-2"
          onClick={(e) => {
            e.preventDefault();
            // if the user has no documents, go to the "select date" step, otherwise go to the "select documents" step
            setAppointmentStep('showSelectDate');
          }}
        >
          Siguiente
        </Button>
      </div>
      {/* image icon for progress */}
      <ProgressIcon3 className=" w-[330px] h-96 md:block hidden" />
    </div>
  );
}

export default SelectLawyer;
