/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Button, Input, PlusIcon } from '@avila-tek/ui';
import { Promotion } from '../../../models';
import { useCountry } from '../../../hooks/useCountry';

interface AppointmentPaymentSummaryProps {
  price: number;
  rate: number;
  legalFee: number;
  symbol: string;
  promotionCode?: string;
  setPromotionCode?: React.Dispatch<React.SetStateAction<string>>;
  getPromotion?: () => Promise<void>;
  thanks: boolean;
  promotions?: Promotion[];
  discount?: number;
  onClickStripe?: () => Promise<void>;
  name: string;
  setPromotions?: React.Dispatch<React.SetStateAction<Promotion[]>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppointmentPaymentSummary({
  price,
  rate,
  legalFee,
  symbol,
  promotionCode,
  setPromotionCode,
  getPromotion,
  thanks,
  promotions,
  discount,
  onClickStripe,
  name,
}: AppointmentPaymentSummaryProps) {
  const { currentCountry } = useCountry();

  return (
    <div className="flex flex-col w-full p-2 md:p-0 md:w-2/3 gap-4 relative">
      {/* Resumen de factura */}
      <div className=" rounded-lg z-0  bg-white md:bg-white w-full flex flex-col gap-2">
        <p className="font-text text-lg font-semibold">Resumen de factura</p>
        {/* Product Name */}
        <div className="flex flex-row w-full justify-between items-cwenter my-2">
          <p className="font-text text-base text-black-400">{name}</p>
          {/* Show converted price if rate is available */}
          <p className="font-text text-md font-semibold text-secondary-500">
            {rate ? symbol : 'USD'}{' '}
            {((price ?? 0) * (rate ?? 1)).toLocaleString(
              currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </p>
        </div>
        {/* Agregue un cupon */}
        {thanks === false ? (
          <div className="rounded-lg bg-white w-full flex flex-col ">
            <p className="font-text text-sm font-semibold">
              Agregue un cupón de descuento
            </p>
            <div className="flex flex-row gap-x-2">
              <Input
                placeholder="Cupón de descuento"
                value={promotionCode}
                className="rounded-none border-secondary-500 py-1 mt-1"
                onChange={(e) => {
                  e.preventDefault();
                  setPromotionCode(e.target.value);
                }}
              />
              <PlusIcon
                className=" w-6 h-6 bg-secondary-500 text-white rounded-lg mt-3 p-0.5 hover:bg-opacity-80 "
                onClick={(e) => {
                  e.preventDefault();
                  getPromotion();
                  setPromotionCode('');
                }}
              />
            </div>
          </div>
        ) : null}
        {/* Promotions */}
        {promotions?.length > 0 &&
          promotions?.map((promotion) => (
            <div
              key={promotion._id}
              className="flex flex-row w-full justify-between items-center my-2"
            >
              {/* Promotion Code */}
              <div className="flex gap-1 justify-start items-center">
                <p className="font-text text-base text-neutral-400">
                  {promotion?.code}
                </p>
                {/* TODO Ver si incluir borrar cupon */}
                {/* <TrashIcon
                  className="w-5 h-5 text-neutral-400 cursor-pointer "
                  onClick={(e) => {
                    e.preventDefault();
                    setPromotions(
                      promotions?.filter((p) => p.code !== promotion?.code)
                    );
                  }}
                /> */}
              </div>
              {/* Show promotion discount amount */}
              <p className="font-text text-md font-medium text-neutral-600">
                - {rate ? symbol : 'USD'}{' '}
                {(promotion.type === 'percentage'
                  ? (
                      (price + legalFee) *
                      (promotion?.modifier / 100) *
                      (rate ?? 1)
                    ).toLocaleString(
                      currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : promotion?.modifier * (rate ?? 1)
                ).toLocaleString(
                  currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </p>
            </div>
          ))}

        <p className="font-text font-semibold">Total</p>
        <div className="flex flex-col w-full gap-2 mt-1">
          {rate ? (
            // If rate is defined, show the Total with the symbol
            <div className="flex flex-row justify-between">
              <p className="font-text font-normal text-md">Total en {symbol}</p>
              <p className="font-text font-semibold text-secondary-500">
                {symbol}{' '}
                {(
                  ((price ?? 0) + (legalFee ?? 0) - (discount ?? 0)) *
                  (rate ?? 1)
                ).toLocaleString(
                  currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </p>
            </div>
          ) : null}
          <div className="flex flex-row justify-between">
            <p className="font-text font-normal text-md">Total en USD</p>
            <p className="font-text font-semibold text-secondary-500">
              USD.{' '}
              {(
                (price ?? 0) +
                (legalFee ?? 0) -
                (discount ?? 0)
              ).toLocaleString(
                currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </p>
          </div>
        </div>
      </div>
      {thanks === false ? (
        // If the thanks is false, show the Pay button
        <Button
          className="bg-secondary-500 text-white w-full hover:bg-opacity-80 flex-row gap-x-2 justify-center"
          onClick={(e) => {
            e.preventDefault();
            onClickStripe();
          }}
        >
          <p>Pagar</p>
        </Button>
      ) : null}
    </div>
  );
}

export default AppointmentPaymentSummary;
