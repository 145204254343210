/* eslint-disable no-nested-ternary */
import React from 'react';
import { useQuery } from '@apollo/client';
import { TailSpin } from 'react-loader-spinner';
import { CloseIcon } from '@avila-tek/ui';
import {
  Appointment,
  AppointmentOrder,
  Document,
  Payment,
  User,
} from '../../models';
import {
  GET_APPOINTMENTS,
  GET_ENTERPRISE_LAWYERS,
} from '../../graphql/queries';
import AppointmentPaymentMethodForm from './payments/AppointmentPaymentMethodForm';
import SelectDate from './SelectDate';
import SelectLawyer from './SelectLawyer';
import { useCountry } from '../../hooks/useCountry';
import ThankYouPage from '../../pages/thanks';
import SelectDocuments from './SelectDocuments';
import Modal from '../common/Modal';

interface AppointmentModalProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hasDocuments?: boolean;
  appointmentDocument?: string;
  setAppointmentDocument?: React.Dispatch<React.SetStateAction<string>>;
  documentData?: Document[];
  orderId?: string;
}

function AppointmentModal({
  isOpen,
  setOpen,
  hasDocuments = false,
  appointmentDocument = '',
  setAppointmentDocument,
  documentData = [],
  orderId,
}: AppointmentModalProps) {
  const { currentCountry } = useCountry();

  const { data: lawyersData, loading: lawyersLoading } = useQuery<{
    getEnterpriseLawyers: any;
    lawyers: User[];
  }>(GET_ENTERPRISE_LAWYERS, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        enterprise: documentData[0]?.enterprise?._id ?? '',
        country:
          documentData[0]?.enterprise?.country?._id ?? currentCountry?._id,
      },
      sort: '_ID_DESC',
    },
  });

  const [lawyers, setLawyers] = React.useState([]);

  // Filter lawyers based on currentCountry
  React.useEffect(() => {
    if (currentCountry?.name !== 'Todos') {
      // setLawyers(
      //   // lawyersData?.lawyers?.filter(
      //   //   (lawyer) =>
      //   //     lawyer?.lawyer?.firm?.country?._id === currentCountry?._id &&
      //   //     lawyer?.calendlyId !== '' &&
      //   //     lawyer?.calendlyId &&
      //   //     lawyer?.lawyer?.calendlyKey !== '' &&
      //   //     lawyer?.lawyer?.calendlyKey
      //   // )
      //   lawyersData?.lawyers
      // );
      setLawyers(lawyersData?.getEnterpriseLawyers?.items);
    } else {
      setLawyers(lawyersData?.getEnterpriseLawyers?.items);
    }
  }, [lawyersData, lawyersLoading, currentCountry]);

  const { data, loading, refetch } = useQuery<{ appointments: Appointment[] }>(
    GET_APPOINTMENTS,
    {
      variables: {
        sort: '_ID_DESC',
        filter: {
          country: currentCountry?._id ?? undefined,
        },
        // filter: {
        //   country:
        //     documentData[0]?.enterprise?._id !== ''
        //       ? documentData[0]?.enterprise
        //       : currentCountry?._id
        //       ? currentCountry?._id
        //       : '',
        // },
      },
    }
  );

  // Set the appointment state to the latest appointment in case there is more than one appointment in the country (it should not)
  React.useEffect(() => {
    setAppointment(data?.appointments[0]);
  }, [loading, data?.appointments]);

  // Refetch the appointments when the country changes
  React.useEffect(() => {
    refetch();
  }, [currentCountry, refetch]);

  const [appointment, setAppointment] = React.useState<Appointment>();
  const [appointmentStep, setAppointmentStep] = React.useState('showDocuments');
  // const [appointmentStep, setAppointmentStep] = React.useState('showLawyer');
  const [isFree, setIsFree] = React.useState(false);
  const [calendlyUrl, setCalendlyUrl] = React.useState<string>();
  const [selectedLawyerId, setSelectedLawyerId] = React.useState<string>('');
  const [updatedAppointmentOrder, setUpdatedAppointmentOrder] =
    React.useState<AppointmentOrder>();
  const [updatedPayment, setUpdatedPayment] = React.useState<Payment>();

  const _loading = false;

  return (
    <Modal modalOpen={isOpen} closeModal={() => setOpen(false)}>
      <div className="w-full h-fit flex relative justify-center items-center ">
        {/* A close button that sets the open state to false */}
        <CloseIcon
          className={` w-8 h-8 cursor-pointer top-1 right-1 md:top-4 md:right-4 absolute z-100 ${
            appointmentStep === 'showSelectDate' ? 'hidden' : ''
          } `}
          onClick={(e) => {
            e.preventDefault();
            // If a date was selected, set the open state to false
            setOpen(false);
          }}
        />
        {!appointment || lawyers?.length === 0 ? (
          <div className="container p-8 ">
            <div className="flex gap-2 items-center justify-start ">
              <img
                alt=""
                src="/logos/widu-icon.png"
                className="md:w-12 sm:w-12 w-10 mt-2"
              />
              <p className="text-lg font-medium">¡Lo sentimos!</p>
            </div>
            <p className="text-base ">
              En este momento no hay abogados disponibles para gestionar su
              cita.
            </p>
            Intente más tarde.
          </div>
        ) : _loading || lawyersLoading || loading ? (
          // Show loading spinner if any data is still loading
          <TailSpin
            height={80}
            width={80}
            color="#214497"
            ariaLabel="loading"
          />
        ) : appointmentStep === 'showPayment' ? (
          // Show payment form if appointmentStep is 'showPayment'
          <AppointmentPaymentMethodForm
            appointment={appointment}
            appointmentId={appointment?._id}
            setAppointmentStep={setAppointmentStep}
            setUpdatedAppointmentOrder={setUpdatedAppointmentOrder}
            setUpdatedPayment={setUpdatedPayment}
            isFree={isFree}
            setIsFree={setIsFree}
          />
        ) : appointmentStep === 'showLawyer' ? (
          // Show select lawyer screen if appointmentStep is 'showLawyer'
          <SelectLawyer
            setSelectedLawyerId={setSelectedLawyerId}
            setAppointmentStep={setAppointmentStep}
            lawyers={lawyers}
            setCalendlyUrl={setCalendlyUrl}
            hasDocuments={hasDocuments}
          />
        ) : appointmentStep === 'showDocuments' ? (
          // Show select lawyer screen if appointmentStep is 'showLawyer'
          <SelectDocuments
            setAppointmentStep={setAppointmentStep}
            hasDocuments={hasDocuments}
            appointmentDocument={appointmentDocument}
            setAppointmentDocument={setAppointmentDocument}
            documentData={documentData}
          />
        ) : appointmentStep === 'showSelectDate' ? (
          // Show select date screen if appointmentStep is 'showSelectDate'
          <SelectDate
            setAppointmentStep={setAppointmentStep}
            calendlyUrl={calendlyUrl}
            setOpen={setOpen}
            selectedLawyerId={selectedLawyerId}
            appointment={appointment}
            updatedAppointmentOrder={updatedAppointmentOrder}
            updatedPayment={updatedPayment}
            orderId={orderId}
            appointmentDocument={appointmentDocument}
            isFree={isFree}
          />
        ) : (
          // Show thank you page if appointmentStep is 'showThanks'
          <ThankYouPage />
        )}
        {/* {getProgressIcon()} */}
      </div>
    </Modal>
  );
}

export default AppointmentModal;
