import React from 'react';
import getFlag from '../../lib/getCountryFlag';
import { Country } from '../../models';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  notMargin?: boolean;
  color?: string;
  withIcon?: string;
  withFlag?: Country;
  iconClassName?: string;
  outline?: boolean;
  buttonType?: string;
  active?: boolean;
}

export default function Button({
  children,
  type,
  disabled,
  className,
  notMargin = false,
  color = 'primary',
  outline = false,
  withIcon,
  withFlag,
  iconClassName,
  buttonType,
  active,
  ...rest
}: ButtonProps) {
  // TODO Shadows para que parezca bien el pressed
  if (color === 'success') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`flex gap-2 justify-center items-center py-1.5 px-8 rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-success-300 text-success-300 hover:border-success-200 hover:text-success-200 '
            : 'bg-success-300 hover:bg-success-200 active:bg-success-400 active:shadow-inner text-font-white '
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (color === 'neutral') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`flex gap-2 justify-center items-center py-1.5 px-8 rounded-md focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-neutral-400 text-neutral-400 hover:border-neutral-600 hover:text-neutral-600 '
            : 'bg-neutral-400 hover:bg-neutral-500 active:bg-neutral-600 active:shadow-inner text-font-white '
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (color === 'danger') {
    if (buttonType === 'small-x') {
      return (
        <button
          type="button"
          className={`flex rounded-md px-2 bg-danger-300 hover:bg-danger-200 active:bg-danger-400 active:shadow-inner text-font-white right-1 top-1 ${className}`}
          {...rest}
        >
          {children}
        </button>
      );
    }
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`flex gap-2 justify-center items-center py-1.5 px-8 rounded-md focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-danger-300 text-danger-300 hover:border-danger-200 hover:text-danger-200'
            : 'bg-danger-300 hover:bg-danger-200 active:bg-danger-400 active:shadow-inner text-font-white'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  if (color === 'warning') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`flex gap-2 justify-center items-center xpy-1.5 px-8 rounded-md focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
          notMargin ? '' : 'mr-2'
        } ${
          outline
            ? 'bg-transparent border border-warning-300 text-warning-300 hover:border-warning-200 hover:text-warning-200'
            : 'bg-warning-300 hover:bg-warning-200 active:bg-warning-400 active:shadow-inner text-font-white'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
  if (color === 'none') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`flex gap-2 justify-center items-center rounded-md focus:text-secondary-600 disabled:opacity-50 disabled:cursor-not-allowed bg-transparent text-secondary-500 hover:text-secondary-300 outline-none text-sm ${className}`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  // Country
  if (buttonType === 'country') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`bg-white cursor-pointer hover:scale-105 flex items-center flex-col gap-3 p-4 w-32 shadow-md rounded-md ${className}`}
        disabled={disabled}
        {...rest}
      >
        {withFlag ? getFlag(withFlag, iconClassName) : null}
        {children}
      </button>
    );
  }

  // Home
  if (buttonType === 'home') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`bg-white cursor-pointer hover:scale-105 flex items-center flex-col gap-3 p-3 w-36 shadow-md rounded-md ${className}`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  // sectionButton
  if (buttonType === 'sectionNavbar') {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`w-full bg-transparent cursor-pointer border-b  hover:text-secondary-400 hover:border-b-secondary-400 flex items-center font-normal flex-col gap-3 px-3 pb-1  md:w-fit text-sm ${className} ${
          active
            ? 'border-b-secondary-500 text-secondary-500'
            : 'text-neutral-400 border-b-neutral-400 md:border-b-0'
        }`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }

  // Blue category
  if (buttonType === 'blueCategory') {
    return (
      <div className="flex flex-row bg-primary-100 border border-secondary-500 gap-3 px-2  justify-center items-center rounded-md h-fit">
        <p className="text-secondary-500 text-xs font-normal">{children}</p>
        <button
          // eslint-disable-next-line react/button-has-type
          type={type}
          className={`text-xs text-secondary-500 font-normal ${className}`}
          disabled={disabled}
          {...rest}
        />
      </div>
    );
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`flex gap-2 justify-center items-center py-1.5 px-8  rounded-md focus:outline-none  disabled:opacity-50 disabled:cursor-not-allowed ${className} ${
        notMargin ? '' : 'mr-2'
      } ${
        outline
          ? 'bg-transparent border border-primary-300 text-primary-300 hover:border-primary-200 hover:text-primary-200 '
          : 'bg-secondary-500 hover:bg-secondary-300 active:bg-secondary-600 active:shadow-inner text-font-white '
      }`}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
