/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { LockIcon } from '@avila-tek/ui/src';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation, useQuery } from '@apollo/client';
import { TailSpin } from 'react-loader-spinner';
import { Elements } from '@stripe/react-stripe-js';
import {
  Appointment,
  AppointmentOrder,
  Payment,
  Promotion,
} from '../../../models';
import {
  CREATE_APPOINTMENT_ORDER,
  CREATE_PAYMENT_INTENT_STRIPE,
} from '../../../graphql/mutation';
import { useNotify, useUser } from '../../../hooks';
import { GET_PROMOTION } from '../../../graphql/queries';
import PaymentSummary from './AppointmentPaymentSummary';
import CardPaymentButton from './AppointmentCardPaymentButton';
import ProgressIcon1 from '../../icons/ProgressIcon1';
import ProgressIcon2 from '../../icons/ProgressIcon2';

// Load Stripe API Key
const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

// Configure the appearance of the UI components using CSS variables
const appearance = {
  theme: 'flat',
  variables: {
    fontFamily: ' "Gill Sans", sans-serif',
    fontLineHeight: '1.5',
    borderRadius: '10px',
    colorBackground: '#F6F8FA',
    colorPrimaryText: '#262626',
  },
  rules: {
    '.Block': {
      backgroundColor: 'var(--colorBackground)',
      boxShadow: 'none',
      padding: '12px',
    },
    '.Input': {
      padding: '12px',
    },
    '.Input:disabled, .Input--invalid:disabled': {
      color: 'lightgray',
    },
    '.Tab': {
      padding: '10px 12px 8px 12px',
      borderRadius: 'none',
    },
    '.Tab:hover': {
      borderRadius: 'none',
      boxShadow:
        '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
    },
    '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
      borderRadius: 'none',
      backgroundColor: '#fff',
      boxShadow:
        '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
    },
    '.Label': {
      fontWeight: '500',
    },
  },
} as const;

interface AppointmentPaymentMethodFormProps {
  appointment?: Appointment;
  appointmentId?: string;
  lawyerId?: string;
  setAppointmentStep?: React.Dispatch<React.SetStateAction<string>>;
  setUpdatedAppointmentOrder?: React.Dispatch<
    React.SetStateAction<AppointmentOrder>
  >;
  setUpdatedPayment?: React.Dispatch<React.SetStateAction<Payment>>;
  isFree?: boolean;
  setIsFree?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppointmentPaymentMethodForm({
  appointment,
  appointmentId,

  setAppointmentStep,
  setUpdatedAppointmentOrder,
  setUpdatedPayment,

  setIsFree,
}: AppointmentPaymentMethodFormProps) {
  const [createAppointmentOrder] = useMutation(CREATE_APPOINTMENT_ORDER);
  const [createPaymentIntentStripe] = useMutation(CREATE_PAYMENT_INTENT_STRIPE);
  const [loading, setLoading] = React.useState(false);

  // State variables for discounts
  const [promotionCode, setPromotionCode] = React.useState('');
  const [promotions, setPromotions] = React.useState<Promotion[]>([]);
  const [discount, setDiscount] = React.useState<number>(0);

  // State variables for Stripe payment
  const [showStripe, setShowStripe] = React.useState<boolean>(false);
  const { user } = useUser();
  const notify = useNotify();
  const mountRef = React.useRef<boolean>(false);
  const clientSecretRef = React.useRef<string>();

  // Queries to fetch data from server
  const { data: promotionData, loading: promotionLoading } = useQuery<{
    promotion: Promotion;
  }>(GET_PROMOTION, {
    variables: { filter: { code: promotionCode } },
    fetchPolicy: 'network-only',
  });

  // Function to apply discounts to appointment price
  const applyDiscount = () => {
    const discountCalc = promotions?.reduce(
      (discountValue: number, promo: Promotion) =>
        discountValue +
        (promo.type === 'percentage' && promo.modifier === 100
          ? appointment?.price ?? 0
          : promo.type === 'percentage'
          ? (appointment?.price ?? 0 * promo.modifier) / 100
          : promo.modifier ?? 0),
      0
    );
    if ((appointment?.price ?? 0) - discountCalc <= 0) {
      setDiscount(discountCalc);
      notify(
        'Descuento gratis aplicado con exito, el precio final es de $0',
        'success'
      );
      setShowStripe(false);

      onSubmit();

      //! create appointment order and payment and setAppointmentStep('SelectLawyer')
    } else {
      setDiscount(discountCalc);
    }
  };

  // Function to get promotion details
  // TODO Que funcione para promociones gratis
  const getPromotion = async () => {
    if (!promotionLoading && promotionData?.promotion) {
      if (promotionData?.promotion?.active) {
        const promotion = promotions?.find(
          (promo) =>
            promo._id.toString() === promotionData?.promotion?._id.toString()
        );
        if (!promotion) {
          // Add the promotion to the list of promotions
          const auxPromotions = promotions;
          auxPromotions?.push(promotionData?.promotion);
          setPromotions(auxPromotions);
          applyDiscount();
        } else {
          notify(
            'El código de promoción ya ha sido agregado a la orden',
            'error'
          );
        }
      } else {
        notify('La promoción se encuentra inactiva', 'error');
      }
    } else {
      notify(
        'Ha ocurrido un error, revise el código ingresado en intente nuevamente',
        'error'
      );
    }
  };

  // Set a ref to check if the component has mounted
  React.useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
    }
  }, []);

  // Handle the Stripe payment method
  const onClickStripe = async () => {
    try {
      setShowStripe(true);
      setLoading(true);
      const { data: stripeData } = await createPaymentIntentStripe({
        variables: {
          data: {
            appOrders: [
              {
                appointment: appointmentId,
                promotions: promotions?.map((promo) => promo._id) ?? [],
              },
            ],
            userId: user?._id,
          },
        },
      });
      if (stripeData?.createPaymentIntentStripe?.clientSecret) {
        clientSecretRef.current =
          stripeData?.createPaymentIntentStripe?.clientSecret;
        setUpdatedAppointmentOrder(
          stripeData?.createPaymentIntentStripe?.payments[0]?.appOrder
        );
        setUpdatedPayment(stripeData?.createPaymentIntentStripe?.payments[0]);
      }
      // notify('Se ha creado el paymentIntent exitosamente!', 'success');
    } catch (error) {
      notify(error.message, 'info');
      console.log(error, 'onClickStripeError');
    } finally {
      setLoading(false);
    }
  };

  // submit appointment order
  const onSubmit = async () => {
    try {
      setLoading(true);
      const { data: orderData } = await createAppointmentOrder({
        variables: {
          data: {
            client: user?._id,
            info: {
              appointment: appointmentId,
              documents: [],
              promotions: promotions?.map((promo) => promo._id) ?? [],
            },
          },
        },
      });
      if (orderData?.createAppointmentOrder) {
        setUpdatedAppointmentOrder(orderData?.createAppointmentOrder);
        setIsFree(true);
        notify('Se ha creado la orden exitosamente!', 'success');
        setAppointmentStep('showLawyer');
      }
    } catch (error) {
      notify(error.message, 'info');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col-reverse gap-x-10 gap-y-10 p-5 lg:flex-row lg:p-10">
      {showStripe ? ( // Conditionally render Stripe component if showStripe is true
        loading ? ( // If the page is still loading, show a loading spinner
          <div className=" flex h-56 w-56 items-center justify-center">
            <TailSpin
              height={80}
              width={80}
              color="#214497"
              ariaLabel="loading"
            />
          </div>
        ) : (
          // If loading is finished, show the Stripe component and the payment button
          <div className="flex">
            <div className="flex w-full flex-col gap-y-4 rounded-lg bg-white p-5">
              <p className="font-text text-lg font-semibold">Método de pago</p>
              {typeof clientSecretRef.current === 'string' ? ( // If clientSecretRef.current is a string, render the CardPaymentButton component wrapped in the Elements component
                <Elements
                  options={{
                    clientSecret: clientSecretRef.current,
                    appearance, // Appearance object to style the Stripe element
                  }}
                  stripe={stripePromise}
                >
                  <CardPaymentButton
                    appointment={appointment}
                    setAppointmentStep={setAppointmentStep}
                  />
                </Elements>
              ) : (
                // If clientSecretRef.current is not a string yet, show the loading spinner
                <div className="flex h-96 w-full items-center justify-center">
                  <TailSpin
                    height={80}
                    width={80}
                    color="#214497"
                    ariaLabel="loading"
                  />
                </div>
              )}
              <div className="flex flex-row items-center gap-x-2">
                <div className="bappointment-[1px] bappointment-secondary-500 flex h-6 w-10 items-center justify-center rounded-full">
                  <LockIcon className="h-4 w-4 text-secondary-500" />
                </div>
                <p className="font-text text-xs text-neutral-400 md:text-sm">
                  Protegemos tu información de pagos al utilizar encriptación
                  para garantizar su seguridad
                </p>
              </div>
            </div>
            <ProgressIcon2 className="hidden h-96 w-[550px] md:block" />
          </div>
        )
      ) : (
        // If showStripe is false, render the PaymentSummary component

        <div className="flex items-center justify-center gap-4">
          <PaymentSummary
            price={appointment?.price ?? 0}
            rate={appointment?.country?.currency?.rate ?? 0}
            legalFee={0} // Legal fee is currently hard-coded to 0
            symbol={appointment?.country?.currency?.symbol ?? 'COP'}
            name="Agende una cita"
            thanks={false} // Thanks page is currently not implemented
            promotions={promotions}
            setPromotions={setPromotions}
            promotionCode={promotionCode}
            setPromotionCode={setPromotionCode}
            getPromotion={getPromotion}
            onClickStripe={onClickStripe} // Function to create a Stripe payment intent
            discount={discount} // Total discount applied to the appointment
          />
          <ProgressIcon1 className="hidden h-96 w-[550px] md:block" />
        </div>
      )}
    </div>
  );
}

export default AppointmentPaymentMethodForm;
