/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { CloseIcon } from '@avila-tek/ui';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import { TailSpin } from 'react-loader-spinner';
import { useNotify, useUser } from '../../hooks';
import {
  SEND_APPOINTMENT_EMAIL,
  UPDATE_APPOINTMENT_ORDER,
  UPDATE_PAYMENT,
} from '../../graphql/mutation';
import { Appointment, AppointmentOrder, Payment } from '../../models';
import ProgressIcon4 from '../icons/ProgressIcon4';
import useWindowSize from '../../hooks/useWindowSize';
import Button from '../common/Button';

// Interface to define the prop types
interface SelectDateProps {
  setAppointmentStep: React.Dispatch<React.SetStateAction<string>>;
  calendlyUrl: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  appointment?: Appointment;
  selectedLawyerId?: string;
  updatedAppointmentOrder?: AppointmentOrder;
  updatedPayment?: Payment;
  appointmentDocument?: string;
  orderId?: string;
  isFree?: boolean;
}

function SelectDate({
  setAppointmentStep,
  calendlyUrl,
  setOpen,
  appointment,
  selectedLawyerId,
  updatedAppointmentOrder,
  updatedPayment,
  appointmentDocument = '',
  orderId = '',
  isFree = false,
}: SelectDateProps) {
  // email mutations
  const [sendAppointmentEmail] = useMutation(SEND_APPOINTMENT_EMAIL);

  // Accessing user details from custom hook useUser
  const { user } = useUser();
  const email = user?.email || '';
  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const name = `${user?.firstName} ${user?.lastName}` || '';

  const inlineWidgetRef = React.useRef(null);
  const dateSelectedRef = React.useRef(false);
  const notify = useNotify();
  const router = useRouter();

  // Mutation hooks to update payment and appointment order
  const [updatePayment] = useMutation(UPDATE_PAYMENT);
  const [updateAppointmentOrder] = useMutation(UPDATE_APPOINTMENT_ORDER);

  // Function to update payment and appointment order and notify the user
  const createPaymentFunction = async (e) => {
    const { uri } = e.data.payload.event;
    const regex = /\/scheduled_events\/([a-z0-9-]+)$/i;
    const match = uri.match(regex);
    const eventId = match[1]; // Extracted ID
    try {
      if (!isFree) {
        const { data } = await updatePayment({
          variables: {
            filter: {
              _id: updatedPayment?._id,
            },
            record: {
              status: 'COMPLETED',
            },
          },
        });
      }

      const { data: appointmentOrderData } = await updateAppointmentOrder({
        variables: {
          data: {
            orderId: updatedAppointmentOrder?._id,
            lawyer: selectedLawyerId,
            documents: [appointmentDocument],
            meetingId: eventId,
          },
        },
      });
      if (appointmentOrderData) {
        notify(
          'Cita agendada con éxito. Revise su correo electrónico para encontrar los detalles',
          'success'
        );

        if (window.location.pathname === '/appointments') {
          window.location.reload();
        } else {
          router.push('/appointments');
        }
      }
    } catch (error) {
      console.log(error, 'error al agendar cita');
      notify('Error al agendar cita', 'error');
    }
  };

  // Listening for Calendly events
  useCalendlyEventListener({
    onDateAndTimeSelected: (e) => {},
    onEventScheduled: (e) => {
      dateSelectedRef.current = true;
      createPaymentFunction(e);
    },
  });

  const { width, height } = useWindowSize();

  // send email to client and lawyer function with try catch
  const sendEmailToClientAndLawyer = async (appointmentOrderData) => {
    try {
      // send email to lawyer and client
      await sendAppointmentEmail({
        variables: {
          data: {
            appOrder: appointmentOrderData?.updateAppointmentOrder?._id,
            documentToReview: {
              categoryName:
                appointmentOrderData?.updateAppointmentOrder?.documents[0]
                  ?.category?.name,
              name: appointmentOrderData?.updateAppointmentOrder?.documents[0]
                ?.name,
            },
          },
        },
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      console.log('finally');
    }
  };

  // A container for the date selection interface and other UI elements
  return (
    <div className="flex gap-4 md:gap-0 relative p-4 justify-center items-center md:justify-start md:items-start ">
      {/* Container for the Calendly InlineWidget */}
      <div
        className="w-fit z-100 h-fit overflow-hidden relative"
        ref={inlineWidgetRef}
      >
        {/* If the Calendly URL is not yet available, show a spinner */}
        {calendlyUrl === '' ? (
          <div className="w-full h-96 flex justify-center items-center">
            <TailSpin
              height={80}
              width={80}
              color="#214497"
              ariaLabel="loading"
            />
          </div>
        ) : (
          // Show the Calendly InlineWidget with pre-filled user data
          <InlineWidget
            prefill={{
              email: email ?? '',
              name: firstName + ' ' + lastName ?? '',
            }}
            iframeTitle="Seleccione una fecha y hora para su cita"
            url={`${calendlyUrl}?hide_gdpr_banner=1`}
            // Customize the widget's style
            styles={{
              width: window.innerWidth < 980 ? '300px' : '1000px',
              height: '800px',
              borderColor: '#ccc',
              borderStyle: 'solid',
              bottom: '',
              WebkitOverflowScrolling: 'touch',
            }}
          />
        )}
      </div>
      {/* A close button that sets the open state to false */}
      <CloseIcon
        className="w-8 h-8 cursor-pointer top-8 right-8 absolute"
        onClick={(e) => {
          e.preventDefault();
          if (dateSelectedRef.current) {
            // If a date was selected, set the open state to false
            setOpen(false);
          }
          // Change the current step in the appointment booking process to 'showLawyer'
          setAppointmentStep('showLawyer');
        }}
      />
      {/* An icon indicating the current step in the appointment booking process */}
      <div className="h-full flex items-top flex-col mt-6 justify-start ">
        <ProgressIcon4 className=" w-[330px] h-96 -ml-4 mt-1 md:block hidden " />
        <Button
          notMargin
          className={` mr-4 text-white ${
            dateSelectedRef.current ? '' : 'hidden'
          } `}
          onClick={(e) => {
            e.preventDefault();
            if (dateSelectedRef.current) {
              // If a date was selected, set the open state to false
              router.push('/appointments');
            }
          }}
        >
          Ir a mis citas
        </Button>
      </div>
    </div>
  );
}

export default SelectDate;
