import React from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Appointment } from '../../../models';
import { useNotify, useUser } from '../../../hooks';

interface CardPaymentButtonProps {
  appointment: Appointment;
  setAppointmentStep: React.Dispatch<React.SetStateAction<string>>;
}

function CardPaymentButton({
  appointment,
  setAppointmentStep,
}: CardPaymentButtonProps) {
  const stripe = useStripe(); // Stripe hook to access Stripe object
  const elements = useElements(); // Stripe hook to access Stripe elements object
  const [message, setMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { user } = useUser(); // Custom hook to access user information
  const notify = useNotify(); // Custom hook to show notification

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    // Retrieve payment intent status from Stripe
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  // Handle form submit when user clicks pay button
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          // return_url: `${window.location.protocol}//${window.location.host}/dashboard/thanks?appointmentId=${appointment?._id}`,
        },
        redirect: 'if_required',
      });
      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setMessage(error?.message);
      } else {
        notify('Se ha creado el pago en stripe exitosamente!', 'success');
        setAppointmentStep('showLawyer');
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className=" w-full flex flex-col with-stripe">
      <form className="max-w-[100%]" id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        <div className="w-full flex justify-center">
          {/* The button that triggers the payment */}
          <button
            type="submit"
            className="bg-secondary-500 w-full py-2 md:py-0 h-fit md:w-3/4 font-text font-semibold rounded shadow-xl mx-auto mt-10 md:h-10 drop-shadow-3xl text-white font-display md:text-base hover:bg-opacity-80 text-base placeholder:text-gray-300 px-2"
            disabled={isLoading || !stripe || !elements}
          >
            {isLoading ? (
              /* Show spinner if payment is being processed */
              <div className="spinner" id="spinner" />
            ) : (
              'PROCEDER CON EL PAGO DE LA CITA'
            )}
          </button>
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}

export default CardPaymentButton;
